import Button from "antd/lib/button";
import List from "antd/lib/list";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import { Link, graphql } from "gatsby";
import React from "react";

import ResponsiveTitle from "../components/ResponsiveTitle";

const { Paragraph } = Typography;

// eslint-disable-next-line no-useless-rename
const Page404 = ({ location: { pathname: pathname }, data }) => {
  const pagePaths = data.allSitePage.edges.map((page) => page.node.path);
  const matchingPaths = pagePaths.filter(
    (path) => path.indexOf(pathname.replace(/\W/g, "")) > -1
  );
  const homePath = `www.mmucho.com`;

  return (
    <Row justify="center">
      <Space direction="vertical" style={{ width: " 100%" }}>
        <ResponsiveTitle>Page not found.</ResponsiveTitle>
        <Paragraph>
          Oops! The page you are looking for has been relocated or renamed!
        </Paragraph>
        {matchingPaths.length > 0 && (
          <List header="Did you mean one of these?">
            {matchingPaths.map((path) => (
              <List.Item key={path}>
                <Link to={path}>{homePath + path}</Link>
              </List.Item>
            ))}
          </List>
        )}
        <Button type="link">Go back to Mmucho home.</Button>
      </Space>
    </Row>
  );
};

export default Page404;

export const query = graphql`
  query AllPagesQuery {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
